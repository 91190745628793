// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_tJ d_gv d_cs";
export var heroHeaderCenter = "x_gw d_gw d_cs d_dw";
export var heroHeaderRight = "x_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "x_tK d_gr d_cw";
export var heroParagraphCenter = "x_gs d_gs d_cw d_dw";
export var heroParagraphRight = "x_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "x_tL d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "x_tM d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "x_tN d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "x_tP d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "x_tQ d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "x_r2 t_r2";
export var heroExceptionNormal = "x_r3 t_r3";
export var heroExceptionLarge = "x_r4 t_r4";
export var Title1Small = "x_rJ t_rJ t_rc t_rd";
export var Title1Normal = "x_rK t_rK t_rc t_rf";
export var Title1Large = "x_rL t_rL t_rc t_rg";
export var BodySmall = "x_rT t_rT t_rc t_rw";
export var BodyNormal = "x_rV t_rV t_rc t_rx";
export var BodyLarge = "x_rW t_rW t_rc t_ry";