// extracted by mini-css-extract-plugin
export var alignDiscLeft = "q_qC d_fp d_bG d_dv";
export var alignLeft = "q_qh d_fp d_bG d_dv";
export var alignDiscCenter = "q_qD d_fq d_bD d_dw";
export var alignCenter = "q_bP d_fq d_bD d_dw";
export var alignDiscRight = "q_qF d_fr d_bH d_dx";
export var alignRight = "q_qj d_fr d_bH d_dx";
export var footerContainer = "q_qG d_dW d_bW";
export var footerContainerFull = "q_qH d_dT d_bW";
export var footerHeader = "q_kf d_kf";
export var footerTextWrapper = "q_qJ d_w";
export var footerDisclaimerWrapper = "q_km d_km d_ck";
export var badgeWrapper = "q_qK d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "q_qL d_bz d_bJ d_bN d_bL";
export var wide = "q_qM d_cy";
export var right = "q_qN d_bK";
export var line = "q_fk d_fl d_cv";
export var badgeDisclaimer = "q_qP d_bC d_bP d_bJ";
export var badgeContainer = "q_qQ d_bz d_bH d_bP";
export var badge = "q_qR";
export var padding = "q_qS d_c7";
export var end = "q_qT d_bH";
export var linkWrapper = "q_qV d_dB";
export var link = "q_mC d_dB";
export var colWrapper = "q_qW d_cx";
export var consent = "q_f d_f d_bC d_bP";
export var disclaimer = "q_qX d_bz d_bJ";
export var media = "q_qY d_bx d_dy";
export var itemRight = "q_qZ";
export var itemLeft = "q_q0";
export var itemCenter = "q_q1";
export var exceptionWeight = "q_q2 t_rF";