// extracted by mini-css-extract-plugin
export var alignLeft = "y_qh d_bG";
export var alignCenter = "y_bP d_bD";
export var alignRight = "y_qj d_bH";
export var textAlignLeft = "y_tR";
export var textAlignCenter = "y_tS";
export var textAlignRight = "y_tT";
export var hoursInnerWrapperAlt = "y_tV d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "y_tW d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "y_tX d_dw";
export var titleMargin = "y_tY d_cw";
export var hoursInnerInnerWrapper = "y_tZ d_cz";